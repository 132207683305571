<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ customer.customer_name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_CUSTOMERS)"
            @click="deleteCustomer"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_CUSTOMERS)"
            @click="editCustomer"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>
            {{ $t("COMMON.TAGS") }}
          </dt>
          <dd>
            <tags :tags="customer.tags" @tagsUpdated="customerUpdated" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="customer.customer_type == CUSTOMER_TYPE_INDIVIDUAL"
        >
          <dt>{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd>
            {{ customer.firstname }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="customer.customer_type == CUSTOMER_TYPE_INDIVIDUAL"
        >
          <dt>{{ $t("COMMON.LASTNAME") }}</dt>
          <dd>
            {{ customer.lastname }}
          </dd>
        </dl>

        <dl class="row" v-if="customer.customer_type == CUSTOMER_TYPE_COMPANY">
          <dt>{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd>
            {{ customer.company_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ customer.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.PHONE") }}</dt>
          <dd>
            {{ customer.phone }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.COUNTRY") }}</dt>
          <dd>
            {{ customer.country }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATE") }}</dt>
          <dd>
            {{ customer.state }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CITY") }}</dt>
          <dd>
            {{ customer.city }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd>
            {{ customer.zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ customer.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="customer.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="customer.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ customer.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ customer.updated_at | moment("LLLL") }}
          </dd>
        </dl>
      </div>

      <div class="mb-2 mt-4">
        <p class="labe-red">
          {{ $t("COMMON.BILLING_ADDRESS") }}
        </p>
      </div>

      <billing-informations-view
        :billingInformations="customer"
        :type="customer.customer_type"
      />
    </div>
  </div>
</template>

<script>
import {
  CUSTOMER_TYPE_INDIVIDUAL,
  CUSTOMER_TYPE_COMPANY,
} from "@/constants/customers";
import BillingInformationsView from "@/components/BillingInformationsView.vue";
import Tags from "@/components/Tags.vue";

export default {
  name: "customer-view-global",

  components: { Tags, BillingInformationsView },

  props: ["customer"],

  data() {
    return {
      CUSTOMER_TYPE_COMPANY: CUSTOMER_TYPE_COMPANY,
      CUSTOMER_TYPE_INDIVIDUAL: CUSTOMER_TYPE_INDIVIDUAL,
    };
  },

  computed: {},

  created() {},

  methods: {
    customerUpdated() {
      this.$emit("customerUpdated", true);
    },
    editCustomer() {
      this.$emit("onEditCustomer", this.customer);
    },
    deleteCustomer() {
      this.$emit("onDeleteCustomer", this.customer);
    },
  },

  mounted() {},

  watch: {
    customer(customer) {},
  },
};
</script>
